var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Top Locations", gridWidth: "2/3" } },
    [
      _vm.allDataLoaded
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  {
                    key: 0,
                    staticClass: "tabs-shadow-none",
                    attrs: { id: "top-location-tabs" },
                    model: {
                      value: _vm.currentTabIndex,
                      callback: function ($$v) {
                        _vm.currentTabIndex = $$v
                      },
                      expression: "currentTabIndex",
                    },
                  },
                  [
                    _c("vs-tab", { attrs: { label: "Cities" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0",
                        },
                        [
                          _c("top-location-list", {
                            attrs: {
                              applicationId: _vm.applicationId,
                              geographyType: 2,
                              topLocations: _vm.topLocationCities,
                            },
                            on: { onSuccessSave: _vm.handleSaveSuccess },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("vs-tab", { attrs: { label: "Airports" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-general md:ml-0 md:mt-0 mt-0 ml-0",
                        },
                        [
                          _c("top-location-list", {
                            attrs: {
                              applicationId: _vm.applicationId,
                              geographyType: 3,
                              topLocations: _vm.topLocationAirports,
                            },
                            on: { onSuccessSave: _vm.handleSaveSuccess },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }