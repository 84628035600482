var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mt-4" },
          [
            _c(
              "vs-button",
              { staticClass: "mr-4 sm:mb-0 mb-2", on: { click: _vm.onAdd } },
              [_vm._v("Add")]
            ),
          ],
          1
        ),
      ]),
      _c("ag-grid-vue", {
        ref: "agGridTable",
        staticClass: "ag-theme-material mb-3",
        attrs: {
          components: _vm.cellRendererComponents,
          columnDefs: _vm.columnDefs,
          defaultColDef: _vm.defaultColDef,
          rowData: _vm.topLocations,
          gridOptions: _vm.gridOptions,
          rowDragManaged: true,
          animateRows: true,
          domLayout: "autoHeight",
          rowSelection: "single",
          pagination: false,
          suppressPaginationPanel: true,
          context: _vm.context,
        },
        on: {
          "first-data-rendered": _vm.onFirstDataRendered,
          "grid-ready": _vm.onGridReady,
          "grid-size-changed": _vm.onGridSizeChanged,
        },
      }),
      _vm.showLocationAutoComplete
        ? _c("location-editor-auto-complete", {
            attrs: {
              applicationId: _vm.applicationId,
              location: _vm.locationStateForAutoComplete,
              geographyType: _vm.geographyType,
              noSave: true,
            },
            on: {
              onEditSuccess: _vm.onEditSuccessAutoComplete,
              onCancel: function ($event) {
                _vm.showLocationAutoComplete = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }