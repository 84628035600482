<template>
  <travio-center-container pageTitle="Top Locations" gridWidth="2/3">
    <vx-card v-if="allDataLoaded">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model='currentTabIndex' class="tabs-shadow-none" id="top-location-tabs" :key="0">

          <!-- Cities -->
          <vs-tab label="Cities">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <top-location-list
                :applicationId="applicationId"
                :geographyType="2"
                :topLocations="topLocationCities"
                @onSuccessSave="handleSaveSuccess"
              />
            </div>
          </vs-tab>

          <!-- Airports -->
          <vs-tab label="Airports">
            <div class="tab-general md:ml-0 md:mt-0 mt-0 ml-0">
              <top-location-list
                :applicationId="applicationId"
                :geographyType="3"
                :topLocations="topLocationAirports"
                @onSuccessSave="handleSaveSuccess"
              />
            </div>
          </vs-tab>

        </vs-tabs>
      </div>
    </vx-card>
    
  </travio-center-container>
</template>

<script>
import TopLocationList from './TopLocationList.vue'


export default {
  components: {
    TopLocationList,
  },
  props: {
    applicationId: { required: true },
  },
  data () {
    return {
      topLocationAirports: [],
      topLocationCities: [],
      allDataLoaded: false,
      currentTabIndex: 0,
    }
  },
  computed: {
    
  },
  async mounted() {
    this.currentTabIndex = 0
    await this.loadData()
  },
  methods: {
    async loadData() {
      
      this.$vs.loading()
      try {
        console.log('app id: ', this.applicationId)
        const response = await this.$http.get(`api/topLocations/apps/${this.applicationId}`)
        this.topLocations = response.data.topLocations
        this.topLocationAirports = this.topLocations.filter(x => x.typeId == 1)
        this.topLocationCities = this.topLocations.filter(x => x.typeId == 2)
        this.allDataLoaded = true
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close();
    },
    async handleSaveSuccess(location) {
      await this.loadData()
    }
  }
}
</script>

<style>

</style>