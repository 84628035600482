<template>
  <div class="vx-row match-height">
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openLocationList()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/locationlists.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Location Lists</h5>
        </div>
        <p class="text-grey mt-4">Manage your own custom destination lists for your searchboxes</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openTopLocations()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/toplocations.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Top Locations</h5>
        </div>
        <p class="text-grey mt-4">Manage the top locations that appear in your search box when a customer clicks into the autocomplete</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openAccomodationOverrides()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/accommodationoverrides.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Accommodation Overrides</h5>
        </div>
        <p class="text-grey mt-4">Enable/disable properties and set priority ordering from your suppliers API results</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("customproperties")'>
      <vx-card style="cursor: pointer" @click="openPropertyContent()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/propcontentbuilder.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Property Content Loader</h5>
        </div>
        <p class="text-grey mt-4">Upload custom property content for use with the Travelify/Intuitive Contracting Engine</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("customextras")'>
      <vx-card style="cursor: pointer" @click="openCustomExtras()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/customextras.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Custom Extra Upsells</h5>
        </div>
        <p class="text-grey mt-4">Load custom extras to upsell in your travel booking flows</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openRssLinkBuilder()">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/rsslinkbuilder.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">RSS Link Builder</h5>
        </div>
        <p class="text-grey mt-4">Build links to your RSS feed of travel offers for embedding in email newsletters and more</p>
      </vx-card>
    </div>
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("rewards")'>
      <vx-card style="cursor: pointer" @click="openRewardsRule()" >
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/rewards.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Booking Rewards</h5>
        </div>
        <p class="text-grey mt-4">Load custom rewards that your customers can earn on bookings</p>
      </vx-card>
    </div>

    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("memberships")'>
      <vx-card style="cursor: pointer" @click="openTool('application-tools-membership-levels')">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/memberships.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Membership Levels</h5>
        </div>
        <p class="text-grey mt-4">Membership Levels</p>
      </vx-card>
    </div>

    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("gsheetseditor")'>
      <vx-card style="cursor: pointer" @click="openTool('application-tools-google-sheets')">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/gsheetseditor.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Data Editor</h5>
        </div>
        <p class="text-grey mt-4">Access a list of your content editors that allow you to edit dynamic content on your website</p>
      </vx-card>
    </div>

    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openTool('application-tools-gift-vouchers')">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/giftvouchers.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Gift Vouchers</h5>
        </div>
        <p class="text-grey mt-4">Manage your client gift vouchers and usage.</p>
      </vx-card>
    </div>
    
    <div class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4" v-if='appHasFeature("skyscanner")'>
      <vx-card style="cursor: pointer" @click="openTool('application-tools-skyscanner-locations')">
        <div slot="no-body">
          <img src="https://static.travelify.io/tools/skyscanner.png" 
          @error="setAltImgForThumbnail"
          class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">Skyscanner Locations</h5>
        </div>
        <p class="text-grey mt-4">Manage Skyscanner hotel search locations</p>
      </vx-card>
    </div>
    
    <div v-for="item in externalTools" :key="item.id" class="vx-col w-full md:w-1/2 lg:w-1/4 mt-2 mb-4">
      <vx-card style="cursor: pointer" @click="openExternalTool(item.url)">
        <div slot="no-body">
          <img :src="item.imageUrl" 
            @error="setAltImgForThumbnail"
            class="responsive card-img-top">
        </div>
        <div class="flex flex-wrap justify-start">
          <h5 class="mt-2">{{item.name}}</h5>
        </div>
        <p class="text-grey mt-4">{{item.description}}</p>
      </vx-card>
    </div>
  </div>
  
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import Vue from "vue"
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    AgGridVue,
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
     externalTools: [],
    }
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    
    try {
      const response = await this.$http.get(`api/genericTools/apps/${this.applicationId}`)
      this.externalTools = response.data
    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();
  },
  mounted() {
  },
  
  methods: {
    openRssLinkBuilder () {
      this.$router.push({name: 'application-tools-rss-link-builder', 
        params: { applicationId: this.applicationId }
      })
    },
    openAccomodationOverrides () {
      this.$router.push({name: 'application-tools-accommodation-overrides', 
        params: { applicationId: this.applicationId }
      })
    },
    openLocationList () {
      this.$router.push({name: 'application-tools-location-lists', 
        params: { applicationId: this.applicationId }
      })
    },
    openTopLocations () {
      this.$router.push({name: 'application-tools-top-locations', 
        params: { applicationId: this.applicationId }
      })
    },
    openPropertyContent () {
      this.$router.push({name: 'application-tools-property-content', 
        params: { applicationId: this.applicationId }
      })
    },
    openCustomExtras () {
      this.$router.push({name: 'application-tools-extra-groups', 
        params: { applicationId: this.applicationId }
      })
    },
    setAltImgForThumbnail (event) {
      const altText = encodeURIComponent('Tool')
      event.target.src = `https://dummyimage.com/1280X720/009687/ffffff.png&text=${altText}`
    },
    openRewardsRule () {
      this.$router.push({name: 'application-tools-reward-rules-list', 
        params: { applicationId: this.applicationId }
      })
    },
    openTool (routeName) {
      this.$router.push({name: routeName, 
        params: { applicationId: this.applicationId }
      })
    },
    openExternalTool (url) {
      window.open(url, '_blank').focus()
    },
  }
}
</script>

<style>

</style>
