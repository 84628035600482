<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mt-4">
        <vs-button @click="onAdd" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="topLocations"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
      @grid-size-changed="onGridSizeChanged"
    ></ag-grid-vue>
    
    <location-editor-auto-complete 
      v-if="showLocationAutoComplete"
      :applicationId="applicationId"
      :location="locationStateForAutoComplete"
      :geographyType="geographyType"
      :noSave="true"
      @onEditSuccess="onEditSuccessAutoComplete"
      @onCancel="showLocationAutoComplete=false" 
    />
  </div>

</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import LocationEditorAutoComplete from '../location-lists/components/LocationEditorAutoComplete.vue'

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    LocationEditorAutoComplete
  },
  props: {
    applicationId: { required: true },
    geographyType: { type: Number, required: true },
    topLocations: { type: Array, required: true }
  },
  data () {
    return {
      searchRequest: null,
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions
      },
      context: null,
      showLocationAutoComplete: false,
      locationStateForAutoComplete: { jsonData: null },
      editMode: false,
      editLocationId: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    typeId () {
      // Geograph Type from Location API not the same with TypeId 
      // 1 = Airports, 2 = City
      if (this.geographyType == 3) return 1
      if (this.geographyType == 2) return 2
      return 0
    }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

  },
  
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { field: 'priority', hide: true },
      { field: 'typeId', hide: true },
      { headerName: 'Name', field: 'name',  rowDrag: true, width: 450, suppressSizeToFit: false },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }

    this.context = { componentParent: this }

  },
  mounted() {
    console.log('mounted')
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.topLocations, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.topLocations, fromIndex, toIndex)
      
      this.extraGroupList = JSON.parse(JSON.stringify(this.topLocations))

      try {
        let response = await this.$http.post(
          `api/topLocations/apps/${this.applicationId}/order`,
          { typeId: this.typeId, orderItems: this.topLocations })

        this.$_notifySuccess('Successfully reordered top locations');
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()

    }

  },

  methods: {
    onAdd () {
      if(this.topLocations && this.topLocations.length >= 12) {
        this.$_notifyFailure('You can only have 12 top locations for each geography type')
        return
      }

      this.locationStateForAutoComplete = { jsonData: null }
      this.editMode = false
      this.showLocationAutoComplete = true
    },
    onEdit ({id, name}) {
      this.locationStateForAutoComplete = { jsonData: JSON.stringify({
          id: id,
          name: name
        })
      }
      this.editMode = true
      this.editLocationId = id
      this.showLocationAutoComplete = true
    },

    async onDelete(id) {
      this.$vs.loading()

      try {
        await this.$http.delete(`api/topLocations/apps/${this.applicationId}?locationId=${id}&typeId=${this.typeId}`)
        this.$_notifySuccess('Successfully deleted top location');
        this.$emit('onSuccessSave')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close();
      
    },
    async onEditSuccessAutoComplete(locationState) {

      const locationStateObj = JSON.parse(locationState)
      const locationId = parseInt(locationStateObj.id)

      if (isNaN(locationId)) {
        this.$_notifyFailure('Invalid location id')
        return
      }
      
      if (!this.editMode && this.topLocations.find(x => x.id == locationId)) {
        this.$_notifyFailure('Location already exists in top locations')
        return
      }

      if (this.editMode && this.editLocationId != locationId && this.topLocations.find(x => x.id == locationId)) {
        this.$_notifyFailure('Location already exists in top locations')
        return
      }

      this.$vs.loading()

      if (this.editMode) {
        const putData = {
          currentLocationId: this.editLocationId,
          newLocationId: locationId,
          locationName: locationStateObj.name,
          typeId: this.typeId,
          jsonData: locationState
        }
        await this.editLocation(putData)
      } else {
        const postData = {
          locationId: locationId,
          locationName: locationStateObj.name,
          typeId: this.typeId,
          jsonData: locationState
        }
        await this.postLocation(postData)
      }

      this.showLocationAutoComplete = false
      this.$vs.loading.close();
      this.$emit('onSuccessSave')

    },
    async postLocation(postData) {
      try {
        await this.$http.post(`api/topLocations/apps/${this.applicationId}`, postData)
        this.$_notifySuccess('Successfully added top location');
      } catch (error) {
        this.$_notifyFailure(error)
      }
    },
    async editLocation(putData) {
      try {
        await this.$http.put(`api/topLocations/apps/${this.applicationId}`, putData)
        this.$_notifySuccess('Successfully updated top location');
      } catch (error) {
        this.$_notifyFailure(error)
      }
    },
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
      params.api.sizeColumnsToFit();
    }
  }
}
</script>

<style>

</style>
